/* eslint-disable react-hooks/rules-of-hooks */
import React, { useEffect, useMemo, useState } from 'react';
import { connect } from 'react-redux';
import { partial, startsWith, throttle } from 'lodash';

import { showEnforcementDialog } from 'actions/modals';
import * as navActions from 'actions/nav';

import { getEmployeeCustomDocument } from 'selectors/hirePacketConfiguration';

import { UPLOAD_DOCUMENT_PATH } from 'features/employeeOnboarding/newHire/constants';
import {
  selectCanCloseEnforcementDialog,
  selectShouldShowEnforcementDialogOnNavigation,
} from 'features/enforcement/EnforcementBanner/selectors';
import { FULL_SCREEN_PATHS } from 'features/payroll/constants';

import withCurrentRoute from 'components/hocs/withCurrentRoute';

import Navigation from './LeftNavigationComponent';
import { checkHighlightTopMenu, checkStrictSubRoutes } from './utils';

const BREAKPOINTS = {
  1920: {
    topMenuFontSize: 18,
    subMenuFontSize: 16,
    panelWidth: 266,
    rightPadding: 25,
    leftPadding: 34,
  },
  1440: {
    topMenuFontSize: 16,
    subMenuFontSize: 14,
    panelWidth: 241,
    rightPadding: 20,
    leftPadding: 32,
  },
  1366: {
    topMenuFontSize: 16,
    subMenuFontSize: 14,
    panelWidth: 229,
    rightPadding: 8,
    leftPadding: 32,
  },
  1024: {
    topMenuFontSize: 14,
    subMenuFontSize: 12,
    panelWidth: 220,
    rightPadding: 8,
    leftPadding: 24,
  },
  414: {
    topMenuFontSize: 14,
    subMenuFontSize: 12,
    panelWidth: 210,
    rightPadding: 14,
    leftPadding: 22,
  },
  375: {
    topMenuFontSize: 14,
    subMenuFontSize: 12,
    panelWidth: 175,
    rightPadding: 16,
    leftPadding: 22,
  },
  360: {
    topMenuFontSize: 14,
    subMenuFontSize: 12,
    panelWidth: 168,
    rightPadding: 18,
    leftPadding: 22,
  },
};

const COLLAPSED_STATE_SIZES = {
  panelWidth: 72,
  rightPadding: 4,
  leftPadding: 24,
};

const MOBILE_COLLAPSED_STATE_SIZES = {
  panelWidth: 0,
  rightPadding: 0,
  leftPadding: 24,
};

const container = document.getElementById('react-left-nav-root-cell');

function toggleContainer(show) {
  if (show) {
    container.classList.remove('hidden');
  } else {
    container.classList.add('hidden');
  }
}

// to make sure that left nav has the same height as the main content
// we need to set height and width of the left nav
// to the container element that we render via rails
// and the only way to do that is to do that through window.document
function setParentWidth(width, mobile, collapsedState) {
  Array.from(
    window.document.getElementsByClassName('left-nav-container')
  ).forEach(e => {
    e.setAttribute(
      'style',
      `width:${mobile && !collapsedState ? '100%' : `${width}px`}`
    );
    e.setAttribute(
      'style',
      `min-width:${mobile && !collapsedState ? '100%' : `${width}px`}`
    );
  });
}

// by default animation is disable, to not run this animation everytime we do
// a full reload of the page because the width of the left nav by default is 0
function enableAnimation() {
  Array.from(
    window.document.getElementsByClassName('left-nav-container')
  ).forEach(e => e.classList.add('with-animation'));
}

function getBreakpointData(width) {
  const sortedBreakpoints = Object.keys(BREAKPOINTS)
    .map(b => parseInt(b, 10))
    .sort((a, b) => b - a);

  const breakpoint = (
    sortedBreakpoints.find(b => width >= b) ||
    sortedBreakpoints[sortedBreakpoints.length - 1]
  ).toString();

  return { breakpoint, sizes: BREAKPOINTS[breakpoint] };
}

function compareUrls(strictMatch, url1, url2) {
  const strictUrl = checkStrictSubRoutes(url2, strictMatch);

  if (strictUrl) {
    return url1 === url2;
  }

  return startsWith(url1, url2);
}

function isMenuActive(menu, isCurrentUrl) {
  if (isCurrentUrl(menu.url)) {
    return true;
  }
  if (
    menu.additional_urls &&
    menu.additional_urls.find(url => isCurrentUrl(url))
  ) {
    return true;
  }
  return false;
}

function findActiveItem(menus, isCurrentUrl) {
  // eslint-disable-next-line no-restricted-syntax
  for (const [topLevelIndex, topMenu] of menus.entries()) {
    if (topMenu.sub_items && topMenu.sub_items.length > 0) {
      // eslint-disable-next-line no-restricted-syntax
      for (const [subLevelIndex, subMenu] of topMenu.sub_items.entries()) {
        if (isMenuActive(subMenu, isCurrentUrl)) {
          return [topLevelIndex, 'sub_items', subLevelIndex];
        }
      }
    } else if (isMenuActive(topMenu, isCurrentUrl)) {
      return [topLevelIndex];
    }
  }
}

function findResponsiveName(currentBreakpoint, item) {
  if (item.get('responsive_names')) {
    const breakpoint = Object.keys(item.get('responsive_names').toJS())
      .sort()
      .find(b => parseInt(b, 10) >= currentBreakpoint);

    if (breakpoint) {
      return item.getIn(['responsive_names', breakpoint]);
    }
  }

  return item.get('name');
}

const LeftNavigationView = ({
  menuItems,
  currentRoute,
  employeeCustomDocument,
  mobile,
  collapsedState,
  setCollapsedState,
  canCloseEnforcementDialog,
  setEmployeeBreakWaiverTooltip,
  employeeBreakWaiverTooltipOpen,
  shouldShowEnforcementDialog,
  onShowEnforcementDialog,
}) => {
  if (window.location.pathname.includes('sign-up/success')) return <></>;
  toggleContainer(
    !FULL_SCREEN_PATHS.concat(
      UPLOAD_DOCUMENT_PATH,
      `employee_onboarding/custom_documents/${employeeCustomDocument.id}/edit`
    ).some(path => currentRoute.includes(path))
  );

  const initialBreakpoint = getBreakpointData(window.innerWidth);
  const [currentBreakpoint, setCurrentBreakpoint] = useState(
    initialBreakpoint.breakpoint
  );
  const [sizes, setSizes] = useState(initialBreakpoint.sizes);

  useEffect(() => {
    if (mobile) {
      setCollapsedState(true);
      setParentWidth(0);
    }
    enableAnimation();
  }, [mobile, setCollapsedState]);

  useEffect(() => {
    const appContainer = document.querySelector('#js-react-app-container');

    if (appContainer && mobile && !collapsedState) {
      appContainer.setAttribute('style', 'display: none');
    } else {
      appContainer.setAttribute('style', 'display: block');
    }
  }, [mobile, collapsedState]);

  useEffect(() => {
    function handleResize() {
      const newBreakpoint = getBreakpointData(window.innerWidth);
      setSizes(newBreakpoint.sizes);
      setCurrentBreakpoint(newBreakpoint.breakpoint);
    }

    const throttledHandleResize = throttle(handleResize, 200);

    window.addEventListener('resize', throttledHandleResize);
    return () => window.removeEventListener('resize', throttledHandleResize);
  }, []);

  const currentSizes = useMemo(() => {
    let newSizes = sizes;

    if (collapsedState) {
      const correctStateSizes = mobile
        ? MOBILE_COLLAPSED_STATE_SIZES
        : COLLAPSED_STATE_SIZES;
      newSizes = { ...sizes, ...correctStateSizes };
    }

    setParentWidth(newSizes.panelWidth, mobile, collapsedState);

    return newSizes;
  }, [sizes, mobile, collapsedState]);

  let menu = useMemo(() => {
    // 1. try to find a current url in menu items by checking
    //    if their url argument completely equal to current route
    //    (it will help to highlight the correct url, if there are few menus
    //    that starts with the similar url, e.g. /timesheets/payroll_summaries and /timesheets)
    // 2. if we can't find anything we will try to find an active menu by checking if
    //    the current route starts with menu's url
    const path =
      findActiveItem(
        menuItems.toJS(),
        partial(compareUrls, true, currentRoute)
      ) ||
      findActiveItem(
        menuItems.toJS(),
        partial(compareUrls, false, currentRoute)
      );

    if (path) {
      // set top menu as active
      const newMenuItems = menuItems.mergeIn([path[0]], { active: true });
      const activeTopMenuItem = menuItems.get(path[0]);

      if (
        path.length > 1 &&
        !checkHighlightTopMenu(activeTopMenuItem, window.location.pathname)
      ) {
        return newMenuItems.mergeIn(path, { active: true }); // set sub menu as active
      }

      return newMenuItems;
    }
    return menuItems;
  }, [menuItems, currentRoute]);

  menu = useMemo(
    () =>
      menu.map(topMenu => {
        if (topMenu.get('responsive_names')) {
          topMenu = topMenu.set(
            'name',
            findResponsiveName(currentBreakpoint, topMenu)
          );
        }

        if (topMenu.get('sub_items')) {
          return topMenu.set(
            'sub_items',
            topMenu
              .get('sub_items')
              .map(subItem =>
                subItem.set(
                  'name',
                  findResponsiveName(currentBreakpoint, subItem)
                )
              )
          );
        }

        return topMenu;
      }),
    [menu, currentBreakpoint]
  );

  if (!menu || menu.size === 0) {
    return null;
  }

  return (
    <Navigation
      menu={menu}
      sizes={currentSizes}
      collapsedState={collapsedState}
      setCollapsedState={setCollapsedState}
      mobile={mobile}
      setEmployeeBreakWaiverTooltip={setEmployeeBreakWaiverTooltip}
      employeeBreakWaiverTooltipOpen={employeeBreakWaiverTooltipOpen}
      shouldShowEnforcementDialog={shouldShowEnforcementDialog}
      onShowEnforcementDialog={onShowEnforcementDialog}
      canCloseEnforcementDialog={canCloseEnforcementDialog}
    />
  );
};

export default connect(
  state => ({
    menuItems: state.getIn(['session', 'nav', 'left_nav_menu']),
    collapsedState: state.getIn(['nav', 'leftNavCollapsed']),
    employeeBreakWaiverTooltipOpen: state.getIn([
      'nav',
      'employeeBreakWaiverTooltipOpen',
    ]),
    canCloseEnforcementDialog: selectCanCloseEnforcementDialog(state),
    employeeCustomDocument: getEmployeeCustomDocument(state),
    shouldShowEnforcementDialog:
      selectShouldShowEnforcementDialogOnNavigation(state),
  }),
  {
    setCollapsedState: navActions.toggleLeftNav,
    setEmployeeBreakWaiverTooltip: navActions.toggleEmployeeBreakWaiverTooltip,
    onShowEnforcementDialog: showEnforcementDialog,
  }
)(withCurrentRoute(LeftNavigationView));
