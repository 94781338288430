/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useCallback, useState } from 'react';
import { connect } from 'react-redux';
import Box from 'fe-design-base/atoms/Box';
import IconButton from 'fe-design-base/molecules/IconButton';

import { getPayPeriodStartDate } from 'selectors/payroll';

import Drawer from 'components/Drawer';

import { cxHelpers } from 'util/className';

import { InitialViewKeyType } from './constants';
import { selectInitialViewKey } from './selectors';
import { closeStripeCheckoutDrawer as getCloseStripeCheckoutDrawer } from './slice';
import * as views from './views';

const { cx } = cxHelpers('StripeCheckoutDrawer');
export interface StripeCheckoutDrawerProps {
  closeStripeCheckoutDrawer: () => any;
  initialViewKey?: InitialViewKeyType;
  payPeriodStartDate: string;
}

export const StripeCheckoutDrawer: React.FC<StripeCheckoutDrawerProps> = ({
  closeStripeCheckoutDrawer,
  initialViewKey,
  payPeriodStartDate,
}) => {
  const [currentViewKey, setCurrentViewKey] = useState(initialViewKey);
  const [isLoading, setIsLoading] = useState(false);
  const CurrentView = views[currentViewKey as keyof typeof views];

  const handleOnLoading = useCallback((loading: boolean) => {
    setIsLoading(loading);
  }, []);

  const handleOnSuccess = useCallback(() => {
    setCurrentViewKey('Success');
  }, []);

  return (
    <Drawer className={cx()} maxWidth="464px" open>
      <Box hright mt={16} mr={16}>
        <IconButton
          onClick={closeStripeCheckoutDrawer}
          icon="Close"
          size="small"
          uxElement="checkout_drawer.close_button"
        />
      </Box>
      <Box mt={12} ph={32}>
        <CurrentView
          onSuccess={handleOnSuccess}
          onLoading={handleOnLoading}
          isLoading={isLoading}
          payPeriodStartDate={payPeriodStartDate}
          closeDrawer={closeStripeCheckoutDrawer}
        />
      </Box>
    </Drawer>
  );
};
export default connect(
  state => ({
    payPeriodStartDate: getPayPeriodStartDate(state),
    initialViewKey: selectInitialViewKey(state),
  }),
  {
    closeStripeCheckoutDrawer: getCloseStripeCheckoutDrawer,
  }
)(StripeCheckoutDrawer);
