import { createSlice } from '@reduxjs/toolkit';
import { fromJS } from 'immutable';

import * as thunks from './thunks';

const slice = createSlice({
  name: 'tipPoolingPolicies',
  initialState: fromJS({
    locationEligible: false,
    currentPayrollPeriod: [],
    previousPayrollPeriod: [],
    tipPoolingPolicies: null,
    isLoading: true,
    showCreatedModal: false,
    showTipPoolDisabledAlert: false,
    showTipPoolEnabledAlert: false,
    showTipPoolUpdatedAlert: false,
    tipPoolEffectiveDate: '',
    locationRoles: [],
    showNewRoleAddedSuccessAlert: false,
  }),
  reducers: {
    dismissCreatedModal: state => state.set('showCreatedModal', false),
    dismissTipPoolDisabledAlert: state =>
      state.set('showTipPoolDisabledAlert', false),
    dismissTipPoolEnabledAlert: state =>
      state.set('showTipPoolEnabledAlert', false),
    dismissTipPoolUpdatedAlert: state =>
      state.set('showTipPoolUpdatedAlert', false),
    dismissNewRoleAddedSuccessAlert: state =>
      state.set('showNewRoleAddedSuccessAlert', false),
  },
  extraReducers: {
    [thunks.createTipPolicy.pending]: state =>
      state.merge({ isSubmitting: true }),
    [thunks.createTipPolicy.rejected]: state =>
      state.merge({ isSubmitting: false }),
    [thunks.createTipPolicy.fulfilled]: (state, { payload }) =>
      state
        .merge({ isSubmitting: false })
        .set('tipPoolingPolicies', [payload])
        .set('tipPoolEffectiveDate', payload.effective_date)
        .set('showCreatedModal', true),
    [thunks.enableTipPolicy.pending]: state =>
      state.merge({ isSubmitting: true }),
    [thunks.enableTipPolicy.rejected]: state =>
      state.merge({ isSubmitting: false }),
    [thunks.enableTipPolicy.fulfilled]: (state, { payload }) =>
      state
        .merge({ isSubmitting: false })
        .set('showTipPoolEnabledAlert', true)
        .set('tipPoolingPolicies', [payload]),
    [thunks.updateTipPolicy.pending]: state =>
      state.merge({ isSubmitting: true }),
    [thunks.updateTipPolicy.rejected]: state =>
      state.merge({ isSubmitting: false }),
    [thunks.updateTipPolicy.fulfilled]: (state, { payload }) =>
      state
        .merge({ isSubmitting: false })
        .set('showTipPoolUpdatedAlert', true)
        .set('tipPoolingPolicies', [payload]),
    [thunks.disableTipPolicy.pending]: state =>
      state.merge({ isSubmitting: true }),
    [thunks.disableTipPolicy.rejected]: state =>
      state.merge({ isSubmitting: false }),
    [thunks.disableTipPolicy.fulfilled]: (state, { payload }) =>
      state
        .merge({ isSubmitting: false })
        .set('showTipPoolDisabledAlert', true)
        .set('tipPoolingPolicies', [payload]),
    [thunks.fetchLocationTipPolicies.pending]: state =>
      state.set('isLoading', true),
    [thunks.fetchLocationTipPolicies.fulfilled]: (state, { payload }) => {
      state = state
        .set('tipPoolingPolicies', payload.tip_pooling_policies)
        .set(
          'currentPayrollPeriod',
          fromJS(payload.current_payroll_period).toArray()
        )
        .set(
          'previousPayrollPeriod',
          fromJS(payload.previous_payroll_period).toArray()
        )
        .set('locationEligible', fromJS(payload.location_eligible))
        .set('subscribed', payload.subscription?.subscribed)
        .set('price', payload.subscription?.pricing?.price)
        .set('systemPriceId', payload.subscription?.pricing?.system_id)
        .set('isLoading', false);
      if (payload.roles) {
        state = state.set('locationRoles', payload.roles);
      }
      return state;
    },
    [thunks.fetchLocationTipPolicies.rejected]: state =>
      state.set('isLoading', false),
    [thunks.addNewRole.pending]: state => state.set('isLoading', true),
    [thunks.addNewRole.fulfilled]: (state, { payload }) => {
      payload.department_name = 'Dept. Not Set';
      const roles = [...state.get('locationRoles'), payload];
      return state
        .set('locationRoles', roles)
        .set('showNewRoleAddedSuccessAlert', true)
        .set('isLoading', false);
    },
    [thunks.addNewRole.rejected]: state => state.set('isLoading', false),
  },
});

export const actions = slice.actions;
export const reducer = slice.reducer;
