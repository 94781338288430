import './TooltipWrapper.scss';

import React, { cloneElement, isValidElement, PureComponent } from 'react';
import onClickOutside from 'react-onclickoutside';
import { uniqueId } from 'lodash';
import PropTypes from 'prop-types';

import Box from 'components/Box';
import Popover from 'components/Popover';

import cxHelpers from 'util/className';

export const TRIGGER_OPTIONS = {
  click: 'click',
  hover: 'hover',
  action: 'action',
};

let timeoutId = null;

@onClickOutside
@cxHelpers('TooltipWrapper')
export default class TooltipWrapper extends PureComponent {
  static propTypes = {
    trigger: PropTypes.oneOf(Object.values(TRIGGER_OPTIONS)),
    content: PropTypes.node,
    offset: PropTypes.number,
    enabled: PropTypes.bool,
    preferPlace: PropTypes.string,
    theme: PropTypes.string,
    delay: PropTypes.number,
    getToggle: PropTypes.func,
    isOpen: PropTypes.bool,
    closeCallback: PropTypes.func,
    animate: PropTypes.string,
    style: PropTypes.object,
    fullWidth: PropTypes.bool,
    noPadding: PropTypes.bool,
    tipSize: PropTypes.number,
    dataTestId: PropTypes.string,
    disabled: PropTypes.bool,
  };

  static defaultProps = {
    trigger: TRIGGER_OPTIONS.hover,
    enabled: true,
    preferPlace: 'below',
    disabled: false,
  };

  componentDidMount() {
    if (this.props.getToggle) {
      this.props.getToggle(this.toggleIsOpen);
    }

    window.addEventListener('scroll', this.handleScroll);
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.handleScroll);
  }

  state = { isOpen: false };
  id = uniqueId('TooltipWrapper');

  static getDerivedStateFromProps(nextProps, prevState) {
    // To solve cases where the `isOpen` value is dynamic and handled via Redux (as opposed to
    // cases where it's handled via interactions such as on-hover events.
    if (!prevState.isOpen && nextProps.isOpen) {
      return { isOpen: true };
    }

    return null;
  }

  isOpen = () => {
    if (this.props.disabled) {
      return false;
    }

    if (
      this.props.trigger === TRIGGER_OPTIONS.action &&
      this.props.isOpen !== null
    ) {
      return this.props.isOpen;
    }
    return this.state.isOpen;
  };

  toggleIsOpen = isOpen => {
    if (isOpen && this.props.delay) {
      timeoutId = window.setTimeout(() => {
        this.setState({ isOpen: true });
      }, this.props.delay);
      return;
    }

    if (!isOpen) {
      window.clearTimeout(timeoutId);

      if (this.props.closeCallback) {
        this.props.closeCallback();
      }
    }

    this.setState({ isOpen });
  };

  handleClickOutside = e => {
    if (
      this.isOpen() &&
      !this.tooltip.contains(e.target) &&
      this.tooltip !== e.target
    ) {
      this.toggleIsOpen(false);
    }
  };

  handleClick = () => {
    if (this.props.trigger === TRIGGER_OPTIONS.click && this.props.enabled) {
      this.toggleIsOpen(!this.isOpen());
    }
  };

  handleMouseEnter = () => {
    if (this.props.trigger === TRIGGER_OPTIONS.hover) {
      this.toggleIsOpen(true);
    }
  };

  handleMouseLeave = () => {
    if (this.props.trigger === TRIGGER_OPTIONS.hover) {
      this.toggleIsOpen(false);
    }
  };

  handleScroll = () => {
    if (this.props.trigger === TRIGGER_OPTIONS.click && this.isOpen()) {
      this.toggleIsOpen(false);
    }
  };

  handleSetTooltipRef = tooltip => (this.tooltip = tooltip);

  render() {
    const {
      children,
      content,
      enabled,
      offset,
      preferPlace,
      theme,
      animate,
      noPadding,
      tipSize,
      dataTestId,
    } = this.props;

    const style = { zIndex: 10001, ...this.props.style };

    return content ? (
      <Popover
        className={this.cx({
          enabled,
          [animate]: animate,
          [theme]: theme,
          noPadding,
        })}
        tipSize={tipSize}
        isOpen={this.isOpen()}
        body={
          <Box data-testid={dataTestId} getRef={this.handleSetTooltipRef}>
            {isValidElement(content)
              ? cloneElement(content, { onClose: this.handleClick })
              : content}
          </Box>
        }
        preferPlace={preferPlace}
        offset={offset}
        style={style}
      >
        <Box
          className="inline"
          onClick={this.handleClick}
          onMouseEnter={this.handleMouseEnter}
          onMouseLeave={this.handleMouseLeave}
          w={this.props.fullWidth ? '100%' : null}
        >
          {children}
        </Box>
      </Popover>
    ) : (
      <Box
        className="inline"
        onClick={this.handleClick}
        onMouseEnter={this.handleMouseEnter}
        onMouseLeave={this.handleMouseLeave}
        w={this.props.fullWidth ? '100%' : null}
      >
        {children}
      </Box>
    );
  }
}
