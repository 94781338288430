import { createSlice } from '@reduxjs/toolkit';
import { fromJS } from 'immutable';

import {
  createCloverAccount,
  fetchCloverEmployees,
  updateOnboardingProperties,
} from './actions';
import { SLICE_NAME } from './constants';

export const initialState = fromJS({
  isPending: false,
  error: {},
  success: {},
  companyName: '',
  employees: [],
});

const slice = createSlice({
  name: SLICE_NAME,
  initialState,
  reducers: {
    setUserSelection: (state, { payload: userSelection }) =>
      state.mergeDeep(fromJS(userSelection)),
  },
  extraReducers: {
    [createCloverAccount.pending]: state =>
      state.set('isPending', true).set('error', fromJS({})),
    [createCloverAccount.fulfilled]: (state, { payload }) =>
      state.mergeDeep({
        isPending: false,
        success: payload,
        error: {},
        companyName: payload ? payload.company.name : '',
      }),
    [createCloverAccount.rejected]: (state, { payload }) =>
      state.set('isPending', false).set('error', fromJS(payload)),

    [updateOnboardingProperties.pending]: state =>
      state.set('isPending', true).set('error', fromJS({})),
    [updateOnboardingProperties.fulfilled]: state =>
      state.mergeDeep({
        isPending: false,
        success: {},
        error: {},
      }),
    [updateOnboardingProperties.rejected]: (state, { payload }) =>
      state.set('isPending', false).set('error', fromJS(payload)),
    [fetchCloverEmployees.pending]: state =>
      state.set('isPending', true).set('error', fromJS({})),
    [fetchCloverEmployees.fulfilled]: (state, { payload }) =>
      state.mergeDeep({
        isPending: false,
        success: {},
        error: {},
        employees: payload.users,
      }),
    [fetchCloverEmployees.rejected]: (state, { payload }) =>
      state.set('isPending', false).set('error', fromJS(payload)),
  },
});

export const { actions, reducer } = slice;
