import { fetchJSON, postJSON, putJSON } from 'api/fetch';
import { filter, isEmpty, map, snakeCase } from 'lodash';
import queryString from 'query-string';

import { BUSINESS_AGE } from 'features/signUp/constants';
import { tryOptionsPayload, userPayload } from 'features/signUp/util/apiHelper';

import { getTimeZone } from 'util/dateTime';
import { resolveError } from 'util/error';
import { getCSRFToken } from 'util/redux';
import { getTemporarySessionId } from 'util/tracking';

import { formattedUtmCampaign, referralPayload } from './util/referralParams';
import {
  DEFAULT_PARTNER_KEY,
  DEFAULT_START_WEEK,
  ENDPOINTS,
} from './constants';

const {
  CREATE_BUSINESS,
  CREATE_EMPLOYEE_BUSINESS,
  CREATE_JOB,
  EMPLOYEE_CHECK,
  LOCATION_SEARCH,
  UPDATE,
  PRE_SIGNUP_EXPERIMENT_ENROLL,
} = ENDPOINTS;

export const postCreateEmployeeBusiness = payload =>
  postJSON(CREATE_EMPLOYEE_BUSINESS, payload).catch(resolveError);

export const fetchEmployeeCheck = ({ phone, email }) =>
  postJSON(EMPLOYEE_CHECK, { phone, email }).catch(resolveError);

export const postCreateJob = ({ locationId }) =>
  postJSON(CREATE_JOB, {
    location_id: locationId,
  }).catch(resolveError);

export const putEmployeeUser = ({ user }) => {
  const payload = {
    user: userPayload(user),
    temporary_session_id: getTemporarySessionId(),
    csrf_token: getCSRFToken(),
    ...referralPayload(),
  };
  return putJSON(UPDATE, payload).catch(resolveError);
};

export const fetchLocationSearch = ({ city, q }) =>
  fetchJSON(
    `${LOCATION_SEARCH}?${queryString.stringify({
      city,
      q,
    })}`
  )
    .then(locations =>
      map(locations, location => ({
        value: location.id,
        label: [location.name, location.city, location.state].join(', '),
      }))
    )
    .catch(resolveError);

export const createBusinessPayload = ({
  ownerInfo,
  ownerMotivation,
  businessInfo: {
    businessName,
    businessZip,
    businessSize: teamSize,
    businessDescription,
    businessExtendedSize: extendedTeamSize,
    multiLocationDetailed,
    geo,
  },
  tryOptions,
  accountDetails: { email, phone, password, textLink },
  experiments,
}) => {
  const {
    timeZone,
    city,
    country_code,
    latitude,
    longitude,
    state,
    zip,
    google_place_attributes,
    hours,
    open_now,
    rating,
    website,
    business_status,
  } = !isEmpty(geo) ? geo.toJS() : {};
  const {
    payrollHowYouPayToday,
    businessAge,
    expectedLaunchDate,
    ...restMotivation
  } = ownerMotivation;
  const { scheduling, payroll, timeTracking } = restMotivation;
  const useType = filter(
    Object.keys(restMotivation),
    key => restMotivation[key]
  )
    .map(key => snakeCase(key))
    .join(', ');

  const { onboard_source, utm_campaign, desired_tier_id } = referralPayload();

  const utmCampaign = utm_campaign || sessionStorage.getItem('campaign_name');

  const payload = {
    owner_params: userPayload({
      fullName: ownerInfo.fullName,
      email,
      password,
      phone,
    }),
    ...(tryOptions.howWillYouTry !== '' && {
      try_options_params: tryOptionsPayload(tryOptions),
    }),
    send_sms: textLink,
    utm_campaign: formattedUtmCampaign(utmCampaign),
    company_params: {
      name: businessName,
      onboard_source,
      company_onboarding_properties_attributes: {
        team_size: teamSize,
        business_description: businessDescription,
        xl_detailed_team_size: teamSize === 'xl' ? extendedTeamSize : null,
        multi_location_detailed: multiLocationDetailed || null,
        locations: multiLocationDetailed === 'multi_location',
        payroll_how_you_pay_today: payrollHowYouPayToday || null,
        desired_tier_id,
        scheduling,
        payroll,
        time_tracking: timeTracking,
        use_type: useType,
        ...(ownerInfo.womAnswer !== '' && {
          wom_answer: ownerInfo.womAnswer,
        }),
        expected_launch_date: expectedLaunchDate,
        ...(tryOptions.howWillYouTry !== '' && {
          how_will_you_try: tryOptions.howWillYouTry,
        }),
        business_age: BUSINESS_AGE[businessAge],
        hours,
        open_now: open_now ? 'yes' : 'no',
        rating,
        website,
        business_status,
        place_id: google_place_attributes?.google_place_id,
      },
    },
    location_params: {
      utm_campaign: formattedUtmCampaign(utmCampaign),
      name: businessName,
      time_zone: timeZone || getTimeZone(),
      start_of_week: DEFAULT_START_WEEK,
      partner_key: DEFAULT_PARTNER_KEY,
      city,
      country_code,
      latitude: latitude?.toString(),
      longitude: longitude?.toString(),
      state,
      zip: businessZip || zip,
      google_place_attributes,
    },
    experiment_params: experiments,
  };

  const { owner_params, ...slimmedPayload } = payload;

  return { payload, slimmedPayload };
};

export const postCreateBusiness = payload =>
  postJSON(CREATE_BUSINESS, payload).catch(resolveError);

export const postPreSignupEnroll = payload =>
  postJSON(PRE_SIGNUP_EXPERIMENT_ENROLL, {
    experiment: {
      name: payload.experimentName,
      unique_identifier: payload.identifier,
    },
  }).catch(resolveError);
