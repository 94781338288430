import { fetchJSON, postJSON, putJSON } from 'api/fetch';
import { reduce } from 'lodash';

import { resolveError } from 'util/error';

import { ENDPOINTS } from './constants';

const {
  CREATE_CLOVER_ACCOUNT,
  SYNC_CLOVER_EMPLOYEES,
  UPDATE_COMPANY_PROPERTIES,
  FETCH_EMPLOYEES,
  INVITE_EMPLOYEES,
  SEND_INSTRUCTIONS,
} = ENDPOINTS;

export const postCreateCloverAccount = payload =>
  postJSON(CREATE_CLOVER_ACCOUNT, payload).catch(resolveError);

export const postSyncCloverEmployees = payload =>
  postJSON(SYNC_CLOVER_EMPLOYEES, payload).catch(resolveError);

export const putOnboardingProperties = payload => {
  const formattedPayload = reduce(
    payload,
    (result, value, key) => {
      const formattedKey = key
        .replace(/\.?([A-Z])/g, (x, y) => '_' + y.toLowerCase())
        .replace(/^_/, '');
      result = {
        ...result,
        [formattedKey]: Array.isArray(value) ? value.join(', ') : value,
      };

      return result;
    },
    {}
  );

  return putJSON(UPDATE_COMPANY_PROPERTIES, {
    company_onboarding_properties: formattedPayload,
  }).catch(resolveError);
};

export const fetchEmployees = () =>
  fetchJSON(FETCH_EMPLOYEES).catch(resolveError);

export const inviteEmployees = () =>
  postJSON(INVITE_EMPLOYEES).catch(resolveError);

export const sendInstructions = () =>
  postJSON(SEND_INSTRUCTIONS).catch(resolveError);
