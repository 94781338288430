// If you are looking to add a new modal, please follow the patterns laid-out
// in this class. These components require a specific user action to be taken
// in order to open the appropriate modal. Only 1 may be open at a time.
import React, { PureComponent, Suspense } from 'react';
import { connect } from 'react-redux';
import Dialog from 'fe-design-base/organisms/Dialog';
import PropTypes from 'prop-types';

import { hideModal } from 'actions/modals';

import { getModalProps, getModalType } from 'selectors/modals';

import withReduxProvider from 'components/hocs/withReduxProvider';
import Loader from 'components/Loader';
import Modal from 'components/modals/Modal';

import cxHelpers from 'util/className';

import MODAL_COMPONENTS from './LazyImports';

@withReduxProvider
@connect(
  state => ({
    modalType: getModalType(state),
    modalProps: getModalProps(state),
  }),
  { onHideModal: hideModal }
)
@cxHelpers('RootModal')
// If you are looking to add a new modal, please follow the patterns laid-out
// in this class. These components require a specific user action to be taken
// in order to open the appropriate modal. Only 1 may be open at a time.
export default class RootModal extends PureComponent {
  static propTypes = {
    onHideModal: PropTypes.func,
    modalProps: PropTypes.object,
    modalType: PropTypes.string,
  };

  handleInnerRef = ref => (this.innerRef = ref);

  handleClose = (e, closedExplicitly) => {
    const {
      modalProps: { onClose },
      onHideModal,
    } = this.props;

    // Hook for custom close functionality.
    return onClose
      ? onClose(() => onHideModal(e, closedExplicitly), closedExplicitly)
      : onHideModal(e, closedExplicitly);
  };

  handleDismiss = () => {
    const {
      modalProps: { onDismiss },
    } = this.props;

    this.innerRef?.handleDismiss?.();
    onDismiss?.();
  };

  renderModalInner = (Component, props) => {
    if (!Component) {
      return null;
    }
    return (
      <Component
        ref={this.handleInnerRef}
        onRequestClose={this.handleClose}
        {...props}
      />
    );
  };

  render() {
    const { modalType, modalProps } = this.props;
    const ModalComponent = MODAL_COMPONENTS[modalType];
    const {
      fullWidth,
      fullScreenAtMobile,
      forceOpen,
      large,
      auto,
      overflowVisible,
      fullScreen,
      circle,
      transparent,
      overlayWindow,
      transparentOverlayWithShadow,
      shouldCloseOnOverlayClick,
      onDismiss,
      rounded,
      roundedSmall,
      closeIconInsideMore,
      closeIcon,
      iconSize,
      deprecatedModal = false,
      /* Designbase Dialog props - start */
      title, // Also used with confirm modal
      width,
      noPadding, // Also used with deprecated modal
      hideCloseIcon, // Also used with deprecated modal
      uxElement,
      disableBackdropClick,
      /* Designbase Dialog props - end */
      ...passThroughProps
    } = modalProps;

    if (!deprecatedModal) {
      return (
        <Suspense fallback={<Loader isLoading centered />}>
          <Dialog
            title={title}
            width={width}
            noPadding={noPadding}
            hideCloseIcon={hideCloseIcon ?? forceOpen}
            disableBackdropClick={disableBackdropClick || forceOpen}
            uxElement={uxElement}
            onClose={this.handleClose}
            open={!!ModalComponent}
          >
            {() => this.renderModalInner(ModalComponent, passThroughProps)}
          </Dialog>
        </Suspense>
      );
    }

    return (
      <Modal
        large={large}
        auto={auto}
        isOpen={!!ModalComponent}
        modalType={modalType}
        onRequestClose={this.handleClose}
        forceOpen={forceOpen}
        fullWidth={fullWidth}
        fullScreenAtMobile={fullScreenAtMobile}
        overflowVisible={overflowVisible}
        noPadding={noPadding}
        roundedSmall={roundedSmall}
        fullScreen={fullScreen}
        hideCloseIcon={hideCloseIcon}
        circle={circle}
        closeIconInsideMore={closeIconInsideMore}
        rounded={rounded}
        transparent={transparent}
        overlayWindow={overlayWindow}
        transparentOverlayWithShadow={transparentOverlayWithShadow}
        shouldCloseOnOverlayClick={shouldCloseOnOverlayClick}
        onDismiss={this.handleDismiss}
        closeIcon={closeIcon}
        iconSize={iconSize}
      >
        <Suspense fallback={<Loader isLoading centered />}>
          {this.renderModalInner(ModalComponent, {
            title,
            ...passThroughProps,
          })}
        </Suspense>
      </Modal>
    );
  }
}
