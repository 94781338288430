import { createSlice } from '@reduxjs/toolkit';
import { isNull } from 'lodash';

import { actionTypes as sessionActionTypes } from 'actions/session';

import {
  fetchFeatures,
  fetchProducts,
  fetchTipManagerSubscriptions,
} from './actions';
import { getBillingCycleIsAnnual } from './util';

const productsSlice = createSlice({
  name: 'products',
  initialState: {
    monthlyProducts: [],
    annualProducts: [],
    features: [],
    isTipManagerSubscribed: false,
  },
  reducers: {
    toggleBillingFrequency: (state, { payload }) => {
      state.isSelectedBillingCycleAnnual = payload === 'annual';
    },
  },
  extraReducers: {
    [fetchProducts.pending]: state => {
      state.monthlyProducts = [];
      state.annualProducts = [];
    },
    [fetchProducts.fulfilled]: (state, action) => {
      state.monthlyProducts = action.payload.monthly;
      state.annualProducts = action.payload.annual;
      state.isSelectedBillingCycleAnnual = getBillingCycleIsAnnual(
        action.payload
      );
    },
    [sessionActionTypes.UPDATE_SESSION]: (state, action) => {
      const { tierProducts } = action.payload;
      if (!tierProducts) return state;

      state.monthlyProducts = tierProducts.monthly;
      state.annualProducts = tierProducts.annual;
      state.isSelectedBillingCycleAnnual =
        getBillingCycleIsAnnual(tierProducts);
    },
    [fetchFeatures.pending]: state => {
      state.features = [];
    },
    [fetchFeatures.fulfilled]: (state, action) => {
      state.features = action.payload.features;
    },
    [fetchTipManagerSubscriptions.pending]: state => {
      state.isTipManagerSubscribed = false;
    },
    [fetchTipManagerSubscriptions.fulfilled]: (state, action) => {
      const activeSubscription = action.payload.subscriptions.find(
        subscription => isNull(subscription.archived_at)
      );
      state.isTipManagerSubscribed = Boolean(activeSubscription);
    },
  },
});

export const actions = productsSlice.actions;
export const reducer = productsSlice.reducer;
