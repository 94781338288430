import './ContactSection.scss';

import React, { memo, useCallback, useMemo, useState } from 'react';
import { connect } from 'react-redux';
import Box from 'fe-design-base/atoms/Box';
import Text from 'fe-design-base/atoms/Text';
import CheckboxField from 'fe-design-base/molecules/CheckboxField';
import PhoneField from 'fe-design-base/molecules/PhoneField';
import TextField from 'fe-design-base/molecules/TextField';
import { useFormikContext } from 'formik';
import PropTypes from 'prop-types';

import { getCurrentLocationId } from 'selectors/session';

import {
  checkEmailAndPhoneStatus,
  resendEmployeeInvite,
} from 'features/team/actions';
import {
  FORM_WITH_STEPS_BOX_STYLE,
  I18N_ERROR_PATH,
  I18N_LABEL_PATH,
  INPUT_ERROR_TYPE,
  SCROLL_BEHAVIOR,
} from 'features/team/components/AddEmployeeForm/constants';
import {
  formatFullName,
  formatResponse,
  generateInputErrorProps,
} from 'features/team/components/AddEmployeeForm/util';
import InputError from 'features/team/components/InputError';
import {
  BUTTON_TEXT,
  ELEMENT,
  EVENT_ACTIONS,
  EVENT_CATEGORIES,
  PRODUCT_AREAS,
} from 'features/teamView/tracking';

import { cxHelpers } from 'util/className';
import { error as flashError } from 'util/flashNotice';
import { toI18n } from 'util/i18n';
import { trackUxEvent } from 'util/tracking';

import SectionTitle from '../../components/SectionTitle';
import InviteOnlyCheckbox from '../OnboardingSection/components/InviteOnlyCheckbox';

const { cx, cxEl } = cxHelpers('ContactSection');

const ContactSection = ({
  children,
  contactInfoRequired,
  formFilloutRecommended,
  locationId,
  onSetIsAdding,
  onSetRehireId,
  onResendInvite,
  onSetRehireLevel,
  onSetHasEmailError,
  onSetHasPhoneError,
  onSetIsCheckingEmail,
  onSetIsCheckingPhone,
  onCheckEmailAndPhoneStatus,
  contactSectionRef,
  isPayrollEnrolled,
  currentTeamMember,
  showContactInfoRequired,
  showFormWithSteps,
}) => {
  const [emailError, setEmailError] = useState(null);
  const [phoneError, setPhoneError] = useState(null);
  const [emailErrorRehiringInProgress, setEmailErrorRehiringInProgress] =
    useState(false);
  const [phoneErrorRehiringInProgress, setPhoneErrorRehiringInProgress] =
    useState(false);
  const [emailResentInvite, setEmailResentInvite] = useState(false);
  const [phoneResentInvite, setPhoneResentInvite] = useState(false);
  const [emailErrorFormValues, setEmailErrorFormValues] = useState(null);
  const [phoneErrorFormValues, setPhoneErrorFormValues] = useState(null);
  const [phoneResendingInvite, setPhoneResendingInvite] = useState(false);
  const [emailResendingInvite, setEmailResendingInvite] = useState(false);
  const { touched, errors, values, setValues } = useFormikContext();
  const emailFieldError = Boolean(touched?.email && errors?.email);
  const phoneFieldError = Boolean(touched?.phone && errors?.phone);
  const emailErrorFullName = formatFullName(
    emailError?.firstName,
    emailError?.lastName
  );
  const phoneErrorFullName = formatFullName(
    phoneError?.firstName,
    phoneError?.lastName
  );

  const onOpenUserProfile = useCallback(userId => {
    trackUxEvent({
      productArea: PRODUCT_AREAS.TEAM,
      eventCategory: EVENT_CATEGORIES.ADD_TEAM_ERROR,
      eventAction: EVENT_ACTIONS.LINK_CLICKED,
      properties: {
        element: ELEMENT.EMPLOYEE_PROFILE,
      },
    });
    window.open(`/team/${userId}`, '_blank');
  }, []);

  const handleResendInvite = useCallback(
    (userId, successCallback, setLoading) => {
      setLoading(true);

      return onResendInvite(userId)
        .then(response => {
          if (response.error) throw new Error();
          trackUxEvent({
            productArea: PRODUCT_AREAS.TEAM,
            eventCategory: EVENT_CATEGORIES.ADD_TEAM_ERROR,
            eventAction: EVENT_ACTIONS.BUTTON_CLICKED,
            properties: {
              button_text: BUTTON_TEXT.RESEND_INVITE,
            },
          });
          successCallback();
        })
        .catch(() => {
          flashError(toI18n('errors.generic'));
        })
        .finally(() => {
          setLoading(false);
        });
    },
    [onResendInvite]
  );

  const trackCancelRehireUxEvent = useCallback(() => {
    trackUxEvent({
      productArea: PRODUCT_AREAS.TEAM,
      eventCategory: EVENT_CATEGORIES.ADD_TEAM_ERROR,
      eventAction: EVENT_ACTIONS.LINK_CLICKED,
      properties: {
        element: ELEMENT.CANCEL_REHIRE,
      },
    });
  }, []);

  const setFieldValues = useCallback(
    fieldValues => setValues({ ...values, ...fieldValues }),
    [setValues, values]
  );

  const populateFormValues = useCallback(
    (errorObj, storeValues) => {
      const { roles, jobId, type, userId, ...rest } = errorObj;

      /** Store values before overwriting */
      storeValues(values);

      /** We need to add all the roles when the ability to add
       * multiple roles is enabled */
      const role = roles[0];
      const fieldValues = { ...rest };

      if (role) {
        fieldValues.defaultRoleName = role.roleName || '';
        fieldValues.wageRate = role.rate || '';
        fieldValues.wageType = role.type;
      }

      const roleWages = roles.slice(1);
      if (roleWages) {
        fieldValues.wageAttributes = roleWages.map(roleWage => ({
          role_name: roleWage.roleName || '',
          wage_type: roleWage.type || '',
          wage_rate: roleWage.rate,
        }));
      }

      /** Set formik values */
      setFieldValues(fieldValues);
    },
    [setFieldValues, values]
  );

  const rehireSecondaryAction = useCallback(
    (errorObj, setErrorFormValues, setErrorRehiringInProgress) => {
      setErrorRehiringInProgress(true);
      onSetIsAdding(false);
      populateFormValues(errorObj, setErrorFormValues);
      onSetRehireId(errorObj.jobId);
      onSetRehireLevel(errorObj.level.toLowerCase());
      contactSectionRef.current.scrollIntoView(SCROLL_BEHAVIOR);
      trackUxEvent({
        productArea: PRODUCT_AREAS.TEAM,
        eventCategory: EVENT_CATEGORIES.ADD_TEAM_ERROR,
        eventAction: EVENT_ACTIONS.BUTTON_CLICKED,
        properties: {
          button_text: BUTTON_TEXT.YES_REHIRE,
        },
      });
    },
    [
      onSetRehireId,
      onSetIsAdding,
      onSetRehireLevel,
      contactSectionRef,
      populateFormValues,
    ]
  );

  const onEmailErrorSecondaryAction = useCallback(
    rehire => {
      if (rehire) {
        rehireSecondaryAction(
          emailError,
          setEmailErrorFormValues,
          setEmailErrorRehiringInProgress
        );
      } else {
        return handleResendInvite(
          emailError.userId,
          () => {
            setEmailResentInvite(true);
          },
          setEmailResendingInvite
        );
      }
    },
    [emailError, handleResendInvite, rehireSecondaryAction]
  );

  const onPhoneErrorSecondaryAction = useCallback(
    rehire => {
      if (rehire) {
        rehireSecondaryAction(
          phoneError,
          setPhoneErrorFormValues,
          setPhoneErrorRehiringInProgress
        );
      } else {
        return handleResendInvite(
          phoneError.userId,
          () => {
            setPhoneResentInvite(true);
          },
          setPhoneResendingInvite
        );
      }
    },
    [phoneError, handleResendInvite, rehireSecondaryAction]
  );

  const getEmailErrorMessage = useMemo(() => {
    switch (emailError?.type) {
      case INPUT_ERROR_TYPE.ACTIVE:
        return toI18n(`${I18N_ERROR_PATH}.email_linked`, {
          props: { name: emailErrorFullName },
        });
      case INPUT_ERROR_TYPE.RESEND_INVITE:
        return toI18n(`${I18N_ERROR_PATH}.invite_email`, {
          props: { name: emailErrorFullName },
        });
      case INPUT_ERROR_TYPE.REHIRE:
        return emailErrorRehiringInProgress
          ? toI18n(`${I18N_ERROR_PATH}.double_check`)
          : toI18n(`${I18N_ERROR_PATH}.rehire_email`, {
              props: { name: emailErrorFullName },
            });
      default:
        return null;
    }
  }, [emailErrorRehiringInProgress, emailError, emailErrorFullName]);

  const getPhoneErrorMessage = useMemo(() => {
    switch (phoneError?.type) {
      case INPUT_ERROR_TYPE.ACTIVE:
        return toI18n(`${I18N_ERROR_PATH}.phone_linked`, {
          props: { name: phoneErrorFullName },
        });
      case INPUT_ERROR_TYPE.RESEND_INVITE:
        return toI18n(`${I18N_ERROR_PATH}.invite_phone`, {
          props: { name: phoneErrorFullName },
        });
      case INPUT_ERROR_TYPE.REHIRE:
        return phoneErrorRehiringInProgress
          ? toI18n(`${I18N_ERROR_PATH}.double_check`)
          : toI18n(`${I18N_ERROR_PATH}.rehire_phone`, {
              props: { name: phoneErrorFullName },
            });
      default:
        return null;
    }
  }, [phoneErrorRehiringInProgress, phoneError, phoneErrorFullName]);

  const handleBlurEmail = useCallback(() => {
    if (phoneErrorRehiringInProgress || emailErrorRehiringInProgress) return;

    const isChangingCurrentTeamMemberEmail = currentTeamMember
      ? currentTeamMember.email !== values.email
      : true;

    if (!emailFieldError && values.email && isChangingCurrentTeamMemberEmail) {
      onSetIsCheckingEmail(true);

      onCheckEmailAndPhoneStatus({ email: values.email })
        .then(response => {
          const userObj = formatResponse(response.users[0], locationId);
          setEmailError(userObj);
          onSetHasEmailError(Boolean(userObj));
        })
        .finally(() => {
          onSetIsCheckingEmail(false);
        });
    }
  }, [
    phoneErrorRehiringInProgress,
    emailErrorRehiringInProgress,
    currentTeamMember,
    values.email,
    emailFieldError,
    onSetIsCheckingEmail,
    onCheckEmailAndPhoneStatus,
    locationId,
    onSetHasEmailError,
  ]);

  const handleBlurPhone = useCallback(() => {
    if (phoneErrorRehiringInProgress || emailErrorRehiringInProgress) return;

    const isChangingCurrentTeamMemberPhone = currentTeamMember
      ? currentTeamMember.phone !== values.phone
      : true;

    if (!phoneFieldError && values.phone && isChangingCurrentTeamMemberPhone) {
      onSetIsCheckingPhone(true);

      onCheckEmailAndPhoneStatus({ phone: values.phone })
        .then(response => {
          const userObj = formatResponse(response.users[0], locationId);
          setPhoneError(userObj);
          onSetHasPhoneError(Boolean(userObj));
        })
        .finally(() => {
          onSetIsCheckingPhone(false);
        });
    }
  }, [
    phoneErrorRehiringInProgress,
    emailErrorRehiringInProgress,
    currentTeamMember,
    values.phone,
    phoneFieldError,
    onSetIsCheckingPhone,
    onCheckEmailAndPhoneStatus,
    locationId,
    onSetHasPhoneError,
  ]);

  const handleChangeEmail = useCallback(() => {
    if (emailError) {
      setEmailError(null);
      onSetHasEmailError(false);
      setEmailErrorRehiringInProgress(false);
      setEmailResendingInvite(false);
      setEmailResentInvite(false);
    }
  }, [emailError, onSetHasEmailError]);

  const handleChangePhone = useCallback(() => {
    if (phoneError) {
      setPhoneError(null);
      onSetHasPhoneError(null);
      setPhoneErrorRehiringInProgress(false);
      setPhoneResendingInvite(false);
      setPhoneResentInvite(false);
    }
  }, [phoneError, onSetHasPhoneError]);

  const getEmailPrimaryAction = useMemo(
    () =>
      emailErrorRehiringInProgress
        ? () => {
            /** Cancel rehire */
            setEmailErrorRehiringInProgress(false);
            onSetIsAdding(!phoneErrorRehiringInProgress);
            trackCancelRehireUxEvent();
            /** Reset formik values to state before rehire */
            setFieldValues(emailErrorFormValues);
            setEmailErrorFormValues(null);
            onSetRehireId(null);
            onSetRehireLevel(null);
            document.getElementsByName('firstName')[0].focus();
          }
        : () => onOpenUserProfile(emailError.userId),
    [
      emailError,
      onSetRehireId,
      onSetIsAdding,
      setFieldValues,
      onSetRehireLevel,
      onOpenUserProfile,
      emailErrorFormValues,
      emailErrorRehiringInProgress,
      phoneErrorRehiringInProgress,
      trackCancelRehireUxEvent,
    ]
  );

  const getPhonePrimaryAction = useMemo(
    () =>
      phoneErrorRehiringInProgress
        ? () => {
            /** Cancel rehire */
            setPhoneErrorRehiringInProgress(false);
            onSetIsAdding(!emailErrorRehiringInProgress);
            trackCancelRehireUxEvent();
            /** Reset formik values to state before rehire */
            setFieldValues(phoneErrorFormValues);
            setPhoneErrorFormValues(null);
            onSetRehireId(null);
            onSetRehireLevel(null);
            document.getElementsByName('firstName')[0].focus();
          }
        : () => onOpenUserProfile(phoneError?.userId),
    [
      phoneError,
      onSetRehireId,
      onSetIsAdding,
      setFieldValues,
      onSetRehireLevel,
      emailErrorRehiringInProgress,
      onOpenUserProfile,
      phoneErrorFormValues,
      phoneErrorRehiringInProgress,
      trackCancelRehireUxEvent,
    ]
  );

  const getEmailErrors = useMemo(
    () =>
      generateInputErrorProps({
        errorObj: emailError,
        errorMessage: getEmailErrorMessage,
        rehiringInProgress: emailErrorRehiringInProgress,
        resendingInvite: emailResendingInvite,
        resentInvite: emailResentInvite,
        onPrimaryAction: getEmailPrimaryAction,
        onSecondaryAction: () =>
          onEmailErrorSecondaryAction(
            emailError?.type === INPUT_ERROR_TYPE.REHIRE
          ),
      }),
    [
      emailError,
      getEmailErrorMessage,
      emailErrorRehiringInProgress,
      emailResendingInvite,
      emailResentInvite,
      getEmailPrimaryAction,
      onEmailErrorSecondaryAction,
    ]
  );

  const getPhoneErrors = useMemo(
    () =>
      generateInputErrorProps({
        errorObj: phoneError,
        errorMessage: getPhoneErrorMessage,
        rehiringInProgress: phoneErrorRehiringInProgress,
        resendingInvite: phoneResendingInvite,
        resentInvite: phoneResentInvite,
        onPrimaryAction: getPhonePrimaryAction,
        onSecondaryAction: () =>
          onPhoneErrorSecondaryAction(
            phoneError?.type === INPUT_ERROR_TYPE.REHIRE
          ),
      }),
    [
      phoneError,
      getPhoneErrorMessage,
      phoneErrorRehiringInProgress,
      phoneResendingInvite,
      phoneResentInvite,
      getPhonePrimaryAction,
      onPhoneErrorSecondaryAction,
    ]
  );

  const inputProps = {
    readOnly: emailErrorRehiringInProgress || phoneErrorRehiringInProgress,
    size: 'medium',
  };

  const showTopError =
    (emailErrorRehiringInProgress && getEmailErrors.error) ||
    (phoneErrorRehiringInProgress && getPhoneErrors.error);

  const emailErrorExists =
    !emailErrorRehiringInProgress &&
    !phoneErrorRehiringInProgress &&
    getEmailErrors.error;

  const phoneErrorExists =
    !phoneErrorRehiringInProgress &&
    !emailErrorRehiringInProgress &&
    getPhoneErrors.error;

  return (
    <Box
      className={cx()}
      mb={8}
      ref={contactSectionRef}
      {...(showFormWithSteps && FORM_WITH_STEPS_BOX_STYLE)}
    >
      <SectionTitle section="contact_information" />
      {showTopError && (
        <Box mt={16}>
          {emailErrorRehiringInProgress && getEmailErrors.error && (
            <InputError {...getEmailErrors} />
          )}
          {phoneErrorRehiringInProgress && getPhoneErrors.error && (
            <InputError {...getPhoneErrors} />
          )}
        </Box>
      )}

      <Box className={cxEl('grid')} mt={24}>
        <TextField
          autoFocus={formFilloutRecommended}
          hasAsterisk
          name="firstName"
          label={toI18n(`${I18N_LABEL_PATH}.first_name`)}
          {...inputProps}
        />

        <TextField
          name="lastName"
          hasAsterisk={isPayrollEnrolled}
          label={toI18n(`${I18N_LABEL_PATH}.last_name`)}
          {...inputProps}
        />

        {showFormWithSteps && (
          <Box className={cxEl('take-two')} mt={-8} mb={12}>
            <CheckboxField
              name="namesMatchLegalNames"
              label={
                <Text
                  variant="body"
                  color="mono900"
                  i18n={`${I18N_LABEL_PATH}.names_match_legal_names`}
                  i18nProps={{
                    teamMemberFirstName: values.firstName || 'Team member',
                  }}
                />
              }
            />
          </Box>
        )}

        <Box
          className={cxEl('take-two')}
          maxw={500}
          column
          gap={emailFieldError ? 10 : 0}
        >
          <TextField
            name="email"
            label={toI18n(`${I18N_LABEL_PATH}.email`)}
            onBlur={handleBlurEmail}
            onChangeCapture={handleChangeEmail}
            error={emailErrorExists}
            {...inputProps}
          />
          {emailErrorExists && <InputError {...getEmailErrors} />}
        </Box>

        <PhoneField
          name="phone"
          onBlurCapture={handleBlurPhone}
          onChangeCapture={handleChangePhone}
          error={phoneErrorExists}
          {...inputProps}
        />
        {phoneErrorExists && (
          <Box
            className={cxEl('take-two')}
            mt={phoneFieldError ? -6 : -16}
            mb={20}
          >
            <InputError {...getPhoneErrors} />
          </Box>
        )}
        {showContactInfoRequired && (
          <Box className={cxEl('take-two')} mb={20}>
            <InviteOnlyCheckbox
              showContactInfoRequired
              contactInfoRequired={contactInfoRequired}
            />
          </Box>
        )}
      </Box>

      {showFormWithSteps && children}
    </Box>
  );
};

ContactSection.propTypes = {
  formFilloutRecommended: PropTypes.bool.isRequired,
  locationId: PropTypes.number.isRequired,
  onSetIsAdding: PropTypes.func.isRequired,
  onResendInvite: PropTypes.func.isRequired,
  onSetRehireLevel: PropTypes.func.isRequired,
  onSetHasEmailError: PropTypes.func.isRequired,
  onSetHasPhoneError: PropTypes.func.isRequired,
  onSetIsCheckingEmail: PropTypes.func.isRequired,
  onSetIsCheckingPhone: PropTypes.func.isRequired,
  onCheckEmailAndPhoneStatus: PropTypes.func.isRequired,
};

ContactSection.defaultProps = {
  onCheckEmailAndPhoneStatus: checkEmailAndPhoneStatus,
};

export default connect(
  state => ({
    locationId: getCurrentLocationId(state),
  }),
  {
    onResendInvite: resendEmployeeInvite,
  }
)(memo(ContactSection));
